<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="病房智能交互系统" describe="基于物联网传输技术，实现医护人员与住院患者之间直接、可靠的信息联络对医院病床和呼叫设备统一进行管理，并引入智能穿戴设备的应用，护士在动态情况下也能够接收到病房患者的及时呼叫提醒。" />
    <div class="product-content">
      <div class="product-name">产品亮点</div>
      <div class="highlight">
        <div class="highlight-item">
          <div class="item-top">
            <img src="@/assets/product/interaction1.png" alt="">
            <div>多终端病呼联动</div>
          </div>
          <div class="item-bottom">
            整合行业领先的病呼设备，提供可靠、稳定的呼叫通讯。支持床头屏、门口屏、护士手表、智能手环、走廊等多终
          </div>
        </div>
        <div class="highlight-item">
          <div class="item-top">
            <img src="@/assets/product/interaction1.png" alt="">
            <div>患者信息实时更新</div>
          </div>
          <div class="item-bottom">
            动态显示患者基本信息、医护信息、护理等级标识、提醒信息、饮食信息、欠费信息、检查手术提醒信息及各种护理标牌。同时可在床头屏上进行医院宣传查看、服务评价、费用查询、体征查询等操作
          </div>
        </div>
        <div class="highlight-item">
          <div class="item-top">
            <img src="@/assets/product/interaction1.png" alt="">
            <div>健康宣教实时推送</div>
          </div>
          <div class="item-bottom">
            管理后台向床头卡、门口屏、护士站大屏更新视频宣教信息，减轻护士每日宣教工作量，患者可以第一时间观看宣教视频快速掌握保健知识
          </div>
        </div>
      </div>
      <div class="product-name">产品介绍</div>
      <div class="introduction-content">
        <div class="introduction-item">
          <div class="item-left">
            <img class="img img1" src="@/assets/product/interaction2.png" alt="">
          </div>
          <div class="item-right text-content">
            <div class="title">
              <span class="span1">智能交互</span><span>主机</span>
            </div>
            <div class="des">
              实时响应患者发起的换药呼叫、紧急呼叫、卫生间报警、请求增员等多种呼叫信息，可查询呼叫记录、进行托管、广播、喊话，支持多种患者信息展示包括患者的基本信息、医嘱信息、检查信息
            </div>
          </div>
        </div>
        <div class="introduction-item">
          <div class="item-left text-content">
            <div class="title">
              <span class="span1">智能交互</span><span>床头屏</span>
            </div>
            <div class="des">
              电子床头卡代替原先的纸质床头卡，可实现患者信息的动态信息显示，包括患者的基本信息、费用信息、风险标志、饮食情况等，除了可以进行呼叫和对讲外，支持健康宣教和注意事项的接收和查看、费用的查询、医院介绍的查询满意度填写等功能
            </div>
          </div>
          <div class="item-right">
            <img class="img img2" src="@/assets/product/interaction2.png" alt="">
          </div>
        </div>
        <div class="introduction-item">
          <div class="item-left">
            <img class="img img3" src="@/assets/product/interaction3.png" alt="">
          </div>
          <div class="item-right text-content">
            <div class="title">
              <span class="span1">智能交互</span><span>门口屏</span>
            </div>
            <div class="des">
              门口屏实时显示该病房及患者的信息，包括病房号、责任医生、责任护士、液进度等，支持人脸识别和NFC刷卡 (扩展版)医护人员可登录门口屏，查看负责患者的信息、医嘱执行情况、护理信息、手术和检查安排等，方便医护人员获取工作信息
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Banner from '@/components/Banner';
export default {
  name: 'SmartWard',
  components: {
    Banner
  },
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  .product-content {
    background: #f7f8fe;
    .highlight {
      display: flex;
      justify-content: center;
      align-items: center;
      .highlight-item {
        background: #ffffff;
        width: 400px;
        height: 270px;
        padding: 40px;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        .item-top {
          display: flex;
          align-items: flex-end;
          margin-bottom: 20px;
          color: #333333;
          line-height: 33px;
          font-size: 24px;
          font-weight: 500;
          img {
            width: 80px;
            height: 60px;
            margin-right: 26px;
          }
        }
        .item-bottom {
          color: #5d7392;
          line-height: 22px;
          font-size: 16px;
        }
      }
    }
    .introduction-content {
      width: 1400px;
      margin: 0 auto;
      padding-bottom: 212px;
      .introduction-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        padding: 0 92px 0 62px;
        height: 540px;
        &:nth-child(2n) {
          background-color:  #eff6ff;
        }
        .img1 {
          width: 600px;
          height: 430px;
        }
        .img2 {
          width: 494px;
          height: 455px;
        }
        .img3 {
          width: 554px;
          height: 506px;
        }
        .text-content {
          width: 500px;
          .title {
            font-weight: 500;
            color: #333;
            line-height: 45px;
            font-size: 32px;
            margin-bottom: 20px;
            .span1 {
              color: #0279ff;
            }
          }
          .des {
            color: #666666;
            line-height: 22px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
